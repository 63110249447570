import React, { useContext, useState } from "react";

import MainLayout from "../layouts/MainLayout";
import Modal from "../components/Modal";
import QuestionButton from "../components/QuestionButton";
import QuestionSlider from "../components/QuestionSlider";
import QuestionWall from "../components/QuestionWall";
import Seo from "../layouts/SEO";
import questionList from "../data/questions.json";
import { MainContext } from "../providers";
import ResultWall from "../components/ResultWall";
import SecondaryButton from "../components/SecondaryButton";

const IndexPage = () => {
  const { currentQuestion } = useContext(MainContext);
  const [isIntro, setIntro] = useState(true);

  return (
    <MainLayout>
      <Seo
        title="Voll im Bilde - Mit dem VGH Versicherungs-Check"
        description="Jeder Mensch ist anders – hat seine eigenen Ziele, Wünsche, Träume. Umso wichtiger, sich und seine Liebsten so individuell wie möglich abzusichern. Wie das geht? Mit dem VGH Versicherungs-Check."
      />
      {isIntro ? (
        <div>
          <ResultWall isIntro />
          <div className="max-w-4xl px-4 mx-auto my-10">
            <h1 className="text-3xl sm:text-5xl">Voll im Bilde</h1>
            <h3 className="mt-3 text-base sm:text-2xl">
              Mit dem VGH Versicherungs-Check
            </h3>
            <p className="pt-8 pb-10 text-sm leading-6 sm:text-lg">
              Jeder Mensch ist anders. Umso wichtiger, sich und seine Liebsten
              so individuell wie möglich abzusichern. Wie das geht? Mit dem VGH
              Versicherungs-Check. Finden Sie schnell und einfach heraus, welche
              Versicherungen Sie unbedingt haben sollten.
            </p>
            <SecondaryButton
              label="Jetzt starten"
              onClick={() => setIntro(false)}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center flex-1 py-5">
          {questionList[currentQuestion].type === "wall" ? (
            <QuestionWall
              key={questionList[currentQuestion].id}
              wallId={questionList[currentQuestion].wallId ?? 0}
              questionData={questionList[currentQuestion]}
            />
          ) : questionList[currentQuestion].type === "button" ? (
            <QuestionButton
              key={questionList[currentQuestion].id}
              questionData={questionList[currentQuestion]}
            />
          ) : (
            <QuestionSlider
              key={questionList[currentQuestion].id}
              questionData={questionList[currentQuestion]}
            />
          )}
          <Modal />
        </div>
      )}
    </MainLayout>
  );
};

export default IndexPage;
