import React, { Fragment, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";

import QuestionButton from "../QuestionButton";
import { MainContext } from "../../providers";
import * as styles from "./styles.module.scss";

const Modal: React.FC = () => {
  const { showModal, modalData, closeModal } = useContext(MainContext);

  if (!modalData || !modalData.answers) return null;
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog as="div" className={styles.dialog} onClose={closeModal}>
        <div className={styles.wrapper}>
          <Dialog.Overlay className={styles.overlay} />

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className={styles.content}>
              <QuestionButton isModal questionData={modalData} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
