import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import classNames from "classnames";

import * as styles from "./styles.module.scss";

type Props = {
  className?: string;
  defaultValue?: number;
  max?: number;
  min?: number;
  onChange?: (value: number | number[]) => void;
  step?: number;
};

const CustomSlider: React.FC<Props> = ({
  className = "",
  defaultValue,
  max = 100,
  min = 0,
  onChange = () => {},
  step = 1,
}) => {
  return (
    <>
      <Slider
        className={classNames(className, styles.slider)}
        defaultValue={defaultValue}
        max={max}
        min={min}
        onChange={onChange}
        step={step}
      />
    </>
  );
};

export default CustomSlider;
