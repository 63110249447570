import React from "react";
import classNames from "classnames";

import DynamicImage from "../DynamicImage";
import * as styles from "./styles.module.scss";
import Icon from "../Icon";

type Props = {
  answerData: any;
  data: any;
  isSelected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const ImageItem: React.FC<Props> = ({
  answerData,
  isSelected = false,
  onClick = () => {},
}) => (
  <>
    <div className={styles.wrapper} role="button" onClick={onClick}>
      <div className={classNames(styles.imageWrapper)}>
        <DynamicImage imageName={answerData.image} />
        {isSelected && <Icon name="check" className={styles.checked}></Icon>}
      </div>
      <p
        className={classNames(styles.label, {
          [styles.selected]: isSelected,
        })}
      >
        {answerData.label}
      </p>
    </div>
  </>
);

export default ImageItem;
