// extracted by mini-css-extract-plugin
export var answers = "styles-module--answers--7S91i";
export var buttonWrapper = "styles-module--buttonWrapper--tMxwb";
export var cash = "styles-module--cash--RBAzQ";
export var cashWrapper = "styles-module--cashWrapper--VQsMI";
export var next = "styles-module--next--usDs1";
export var prev = "styles-module--prev--pPolQ";
export var question = "styles-module--question--GMEHm";
export var questionIndex = "styles-module--questionIndex--Pmp2b";
export var slider = "styles-module--slider--MDCBd";
export var wrapper = "styles-module--wrapper---BZ0T";