import React from "react";
import classNames from "classnames";
import * as styles from "./style.module.scss";
import Icon from "../Icon";

type Props = {
  className?: string;
  data: any;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  selected?: boolean;
};

export default function Button({
  className = "",
  data,
  disabled = false,
  onClick = () => {},
  selected = false,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        disabled={disabled}
        className={classNames(className, styles.buttonStyle, {
          [styles.selected]: selected,
        })}
        onClick={onClick}
      >
        {data.label}
      </button>
      {selected && <Icon name="check" className={styles.checked}></Icon>}
    </div>
  );
}
