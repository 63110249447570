import React, { useContext, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import classNames from "classnames";

import Icon from "../Icon";
import ImageItem from "../ImageItem";
import WallImage from "../WallImage";
import { MainContext } from "../../providers";
import { QuestionType } from "../../types";
import * as styles from "./styles.module.scss";

type Props = {
  questionData: QuestionType;
  wallId: number;
};

const QuestionWall: React.FC<Props> = ({ questionData, wallId }) => {
  const { id, question, isMulti, answers } = questionData;
  const {
    onNextPage,
    onPrevPage,
    setModalData,
    openModal,
    closeModal,
    setAnswer,
    answerData,
    currentQuestion,
    wallData,
    setChildQuestionId,
  } = useContext(MainContext);
  const wallQuestionIndex = currentQuestion < 3 ? currentQuestion : 0;
  const [localAnswer, setLocalAnswer] = useState<any[]>(
    answerData[currentQuestion]
      ? answerData[currentQuestion].map((item: any) => item.selected ?? false)
      : []
  );

  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const selectAnswer = (index: number) => {
    const newState = !localAnswer[index];
    const prev = [...localAnswer];
    if (!isMulti) prev.fill(false);
    prev[index] = newState;
    setAnswer({ answer: prev, wallId });
    setLocalAnswer([...prev]);
    if (questionData.answers[index].childQuestions && newState) {
      setChildQuestionId(prev => [...prev, index]);
      setModalData(questionData.answers[index].childQuestions[0]);
      openModal();
    } else if (!isMulti) {
      closeModal();
      setModalData({});
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wallWrapper}>
        <div className={styles.wall}>
          <StaticImage
            src="../../assets/images/wall-bg.jpg"
            formats={["auto", "webp", "avif"]}
            alt="Wand"
            objectFit="cover"
            className="w-full h-full"
          />
        </div>
        <div
          className={classNames(styles.wallContainer, styles[`wall${wallId}`])}
        >
          {wallData[wallId]?.map((items: any) =>
            items.map((item: any, index: number) => (
              <WallImage
                key={index}
                image={item.image}
                isVisible={item.selected ?? false}
              />
            ))
          )}
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.questionIndex}>{id}/9</p>
        <p className={styles.question}>{question}</p>
        <div className={styles.answers}>
          {answers.map((item, index) => (
            <ImageItem
              answerData={item}
              data={data}
              isSelected={
                wallData[wallId]?.[wallQuestionIndex]?.[index]?.selected ??
                false
              }
              key={index}
              onClick={() => selectAnswer(index)}
            />
          ))}
        </div>
        <div className={styles.buttonWrapper}>
          <div
            className={classNames(styles.prev, "opacity-0", {
              "opacity-100": currentQuestion > 0,
            })}
            role="button"
            onClick={onPrevPage}
          >
            <Icon name="arrow" width={16} height={30} />
            <span>Zurück</span>
          </div>
          {wallData[wallId]?.[wallQuestionIndex]?.find(
            (item: any) => item.selected
          ) && (
            <div className={styles.next} role="button" onClick={onNextPage}>
              <span>Weiter</span>
              <Icon name="arrow" width={16} height={30} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionWall;
