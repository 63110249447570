import React, { useContext, useEffect, useState } from "react";

import Button from "../Button";
import Icon from "../Icon";
import { MainContext } from "../../providers";
import { QuestionType } from "../../types";
import * as styles from "./styles.module.scss";

type Props = {
  isModal?: boolean;
  questionData: QuestionType;
};

const QuestionButton: React.FC<Props> = ({ isModal = false, questionData }) => {
  const {
    answerData,
    closeModal,
    currentQuestion,
    onNextPage,
    onPrevPage,
    openModal,
    setAnswer,
    setModalData,
    childQuestionId,
    setChildQuestionId,
    currentChildQuestion,
    setCurrentChildQuestion,
  } = useContext(MainContext);
  const { id, isMulti, question, answers, required } = questionData;
  const [hasAnswered, setHasAnswered] = useState(false);
  const [localAnswer, setLocalAnswer] = useState<boolean[]>([]);

  const selectAnswer = (index: number) => {
    const newState = !localAnswer[index];
    const prev = [...localAnswer];
    if (!isMulti) prev.fill(false);
    prev[index] = newState;
    setAnswer({ answer: prev, childIdList: childQuestionId });
    setLocalAnswer([...prev]);

    if (questionData.answers[index].childQuestions) {
      if (newState) {
        setChildQuestionId(prev => [...prev, index]);
        openModal();
        setModalData(questionData.answers[index].childQuestions[0]);
      }
    } else if (!isMulti) {
      setModalData(undefined);
      closeModal();
      // onNextPage();
    }
  };

  useEffect(() => {
    const xhasAnswered = localAnswer.includes(true);
    setHasAnswered(xhasAnswered);
    // if (!isMulti && xhasAnswered) {
    //   onNextPage();
    // }
  }, [localAnswer]);

  useEffect(() => {
    switch (childQuestionId?.length) {
      case 0:
        setLocalAnswer(
          answerData[currentQuestion].map((item: any) => item.selected ?? false)
        );
        break;
      case 1:
        setLocalAnswer(
          answerData[currentQuestion][childQuestionId[0]].childQuestions[
            currentChildQuestion
          ].answers.map((item: any) => item.selected ?? false)
        );
        break;
      case 2:
        setLocalAnswer(
          answerData[currentQuestion][
            childQuestionId[0]
          ].childQuestions[0].answers[
            childQuestionId[1]
          ].childQuestions[0].answers.map((item: any) => item.selected ?? false)
        );
        break;
      default:
        break;
    }
  }, [childQuestionId, currentChildQuestion]);
  return (
    <div className={styles.wrapper}>
      {!isModal && <p className={styles.questionIndex}>{id}/9</p>}
      <p className={styles.question}>{question}</p>
      <div className={styles.answers}>
        {answers.map((item, index) => (
          <Button
            key={index}
            data={item}
            selected={localAnswer[index]}
            onClick={() => {
              selectAnswer(index);
            }}
          />
        ))}
      </div>
      {!isModal ? (
        <div className={styles.buttonWrapper}>
          <div className={styles.prev} role="button" onClick={onPrevPage}>
            <Icon name="arrow" width={16} height={30} />
            <span>Zurück</span>
          </div>
          {(hasAnswered || !required) && (
            <div className={styles.next} role="button" onClick={onNextPage}>
              <span>Weiter</span>
              <Icon name="arrow" width={16} height={30} />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.buttonWrapper}>
          {isMulti && hasAnswered && (
            <>
              <div className={styles.prev} role="button"></div>
              <div
                className={styles.next}
                role="button"
                onClick={() => {
                  if (
                    childQuestionId.length === 1 &&
                    currentChildQuestion <
                      answerData[currentQuestion][childQuestionId[0]]
                        .childQuestions.length -
                        1
                  ) {
                    setModalData(
                      answerData[currentQuestion][childQuestionId[0]]
                        .childQuestions[currentChildQuestion + 1]
                    );
                    setCurrentChildQuestion(prev => prev + 1);
                  } else {
                    closeModal();
                  }
                }}
              >
                <span>Weiter</span>
                <Icon name="arrow" width={16} height={30} />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionButton;
