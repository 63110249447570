import React, { useContext, useEffect, useState } from "react";

import CustomSlider from "../Slider";
import DynamicImage from "../DynamicImage";
import { MainContext } from "../../providers";
import { QuestionType } from "../../types";
import * as styles from "./styles.module.scss";
import Icon from "../Icon";

type Props = {
  questionData: QuestionType;
};

const QuestionSlider: React.FC<Props> = ({ questionData }) => {
  const { onNextPage, onPrevPage, setAnswer, answerData, currentQuestion } =
    useContext(MainContext);
  const { id, question, answers } = questionData;
  const [hasAnswered, setHasAnswered] = useState(false);
  const [localAnswer, setLocalAnswer] = useState(
    answerData[currentQuestion]
      ? answerData[currentQuestion].map((item: any) => item.selected ?? false)
      : []
  );

  const [defaultValue] = useState<number | undefined>(() => {
    if (answerData[currentQuestion]) {
      const id = answerData[currentQuestion].findIndex(
        (item: any) => item.selected
      );
      return id > -1 ? id + 1 : 1;
    }
    return 1;
  });

  const selectAnswer = (index: number) => {
    setHasAnswered(true);

    const newState = !localAnswer[index];
    const prev = [...localAnswer];
    prev.fill(false);
    prev[index - 1] = newState;
    setAnswer({ answer: prev });
    setLocalAnswer([...prev]);
  };

  useEffect(() => {
    setHasAnswered(defaultValue !== undefined);
  }, []);

  return (
    <div className={styles.wrapper}>
      <p className={styles.questionIndex}>{id}/9</p>
      <p className={styles.question}>{question}</p>
      <div className={styles.answers}>
        <div className={styles.cashWrapper}>
          {answers.map(item => (
            <div className={styles.cash} key={item.label}>
              <DynamicImage imageName={item.image} />
              {item.label}
            </div>
          ))}
        </div>
        <CustomSlider
          className={styles.slider}
          max={answers.length}
          min={1}
          step={1}
          defaultValue={defaultValue ?? 1}
          onChange={selectAnswer}
        />
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.prev} role="button" onClick={onPrevPage}>
          <Icon name="arrow" width={16} height={30} />
          <span>Zurück</span>
        </div>
        {hasAnswered && (
          <div className={styles.next} role="button" onClick={onNextPage}>
            <span>Weiter</span>
            <Icon name="arrow" width={16} height={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionSlider;
