// extracted by mini-css-extract-plugin
export var answers = "styles-module--answers--ZryfH";
export var buttonWrapper = "styles-module--buttonWrapper--XouIT";
export var contentWrapper = "styles-module--contentWrapper--HEJXl";
export var next = "styles-module--next--+IXDY";
export var prev = "styles-module--prev--pAs+7";
export var question = "styles-module--question--xptxP";
export var questionIndex = "styles-module--questionIndex--k7bvS";
export var wall = "styles-module--wall--ZtUbi";
export var wall0 = "styles-module--wall0--iKP-i";
export var wall1 = "styles-module--wall1--aieBc";
export var wall2 = "styles-module--wall2--0oKSx";
export var wallContainer = "styles-module--wallContainer--HdQx3";
export var wallWrapper = "styles-module--wallWrapper--oS8rc";
export var wrapper = "styles-module--wrapper--i2EPW";